import { useEffect, useState } from "react";
import { mintNFT } from "./utils/interact.js";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from 'ethers';

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const onMintPressed = async () => {
    setStatus("Minting...");
    const status = await mintNFT(account);
    setStatus(status.status);
    //mintNFT();
};
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "ea59f8d2fdf44487bbd464821c5cb7fb"
    }
  }
};

const web3Modal = new Web3Modal({
  providerOptions // required
});
const [provider, setProvider] = useState();
const [library, setLibrary] = useState();
const [account, setAccount] = useState();
const [network, setNetwork] = useState();

const connectWallet = async () => {
  try {
    const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();
    setProvider(provider);
    setLibrary(library);
    if (accounts) setAccount(accounts[0]);
    setNetwork(network);
  } catch (error) {
    console.error(error);
  }
};
  
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
    <div className="Minter">
      <button onClick={connectWallet}>Connect Wallet</button>
        <div>Connection Status: {!!account}</div>
        <div>Wallet Address: {account}</div>
      

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <img className="nftimg" src="https://openseauserdata.com/files/8f7a8d0aaa419da0e6476362cf810fc9.svg"></img>
      <button id="mintButton" onClick={onMintPressed}>
        Mint NFT
      </button>
      <p id="status">
        {status}
      </p>
    </div>
  );
};

export default Minter;
